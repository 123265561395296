<template>
	<div class="flex-1 py-2 pr-4 no-underline">
		<div v-if="comments" class="mb-1 divide-y divide-gray-300">
			<div
				class="flex items-center justify-between py-1 pr-4 text-sm leading-tight pb-"
				v-for="(singleComment, index) in savedComments"
				:key="index"
			>
				<span v-html="singleComment.comment"></span>
				<div class="flex items-center">
					<button @click="deleteComment(singleComment)">
						<svg
							class="w-5 h-5 text-red-600"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>

		<input
			class="w-full h-10 bg-white border border-gray-200 rounded dark:border-gray-400 dark:bg-gray-600"
			type="text"
			name="comment"
			v-model="comment"
			@keyup.enter="onSubmitComment()"
		/>
	</div>
</template>

<script>
	import { repgen } from "@/firebase";
	import { doc, updateDoc } from "firebase/firestore";
	export default {
		props: [
			"id",
			"client",
			"rowId",
			"section",
			"userName",
			"savedComments"
		],
		data() {
			return {
				currentQuestion: [],
				comment: "",
				comments: [],
			};
		},
		mounted() {
			if(this.savedComments) {
				this.comments = this.savedComments
			} else {
				this.comments = []
			}
		},
		methods: {
			async onSubmitComment() {
				// console.log("save comment");
				this.comments = this.savedComments;
				var commentData = {
					id: this.savedComments.length,
					comment:
						this.comment +
						" - <span class='font-medium text-blue-600 dark:text-blue-400'>" +
						this.userName +
						"</span>",
				};
				this.comments.push(commentData);
				this.comment = "";
				var data = {
					comments: this.comments,
				};
				await updateDoc(doc(repgen, "clients", this.client, this.section, this.id), data);
				
				this.comments = []
			},
			async deleteComment(arr) {
				this.comments = this.savedComments;
				var index = this.comments.findIndex((a) => a.id === arr.id);
				if (index !== -1) this.comments.splice(index, 1);

				var data = {
					comments: this.comments,
				};
				await updateDoc(doc(repgen, "clients", this.client, this.section, this.id), data);
			},
		},
	};
</script>

<style></style>
