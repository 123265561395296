<script>
import { repgen } from "@/firebase";
import { doc, updateDoc } from "firebase/firestore"

export default {
    props: ["isRecommended", "client", "section", "docId"],
    data() {
        return {
            recommended: this.isRecommended
        }
    },
    methods: {
        async updateRecommended() {
            this.recommended = !this.recommended;
            var data = {
                recommended: this.recommended,
            };
            await updateDoc(doc(repgen, "clients", this.client, this.section, this.docId), data);
        },
        
    }
}
</script>

<template>
    <div class="w-16 px-3 py-1 text-center">
        <input
        @click="updateRecommended()"
        class="bg-white dark:bg-gray-600 dark:border-gray-800"
        type="checkbox"
        id="checkbox"
        v-model="recommended"
        />
    </div>
</template> 