const repgenSections = [
  { title: "Scalability X Axis" },
  { title: "Scalability Y Axis" },
  { title: "Scalability Z Axis" },
  { title: "Fault Isolation (Swim Lanes)" },
  { title: "Disaster Recovery" },
  { title: "Cost Effectiveness" },
  { title: "Process Product Management" },
  { title: "Process PDLC" },
  { title: "Process Development" },
  { title: "Process Operations" },
  { title: "Organization Knowledge and Alignment" },
  { title: "Security" },
];

export default repgenSections;
