<template>
  <div class="w-full">
    <div class="flex flex-wrap text-gray-600 border-b border-gray-300">
      <div
        class="w-full px-2 py-2 pr-4 font-semibold bg-blue-300 border-r border-gray-200 dark:bg-blue-400 dark:border-gray-500"
      >
      <button v-if="prompts.length > 0" @click="promptSection = !promptSection" class="align-text-top focus:outline-none">
            <svg
              class="w-5 h-5 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          {{ section }} - {{ owner }}
      </div>
      <!-- <p class="text-xs text-gray-300">
        {{ repgenSection }}
      </p> -->

      <div
        v-if="promptSection"
        class="flex items-start w-2/3 py-3 pl-3 pr-4 text-gray-700 bg-gray-100 border-b border-gray-200 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-700"
      >
        <button @click="promptSection = !promptSection" class="mt-1 mr-12 focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 text-blue-800 dark:text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <ul>
          <li v-for="(prompt, index) in prompts" :key="index" class="mt-1 ">
            {{ prompt }}
          </li>
        </ul>
      
    
    </div>
      
      <div
        v-for="(question, index) in repgenSection"
        :key="index"
        class="flex items-center w-full text-gray-600 border-b border-gray-200 dark:border-gray-500"
      >
        <!-- <pre>
          {{question}}
        </pre> -->
        <div class="w-full">
        <div
        class="flex items-center w-full pl-2 text-gray-300 bg-white dark:bg-gray-600"
        :class="question.recommended ? 'text-gray-600 dark:text-gray-300' : 'text-gray-400 line-through bg-gray-100'"
        >
        <div class="w-8 px-2 mt-1 text-gray-400">{{ index + 1 }}.</div>
        <div v-if="question.tips" class="w-6 px-1 pt-1 ">
          <button v-if="question.tips.length > 0" @click="helpSection = !helpSection" class="mt-2 focus:outline-none">
            <svg
              class="w-5 h-5 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        <div class="w-1/2 px-4 pt-3 pb-2">{{ question.question }}</div>
        <div class="w-24 py-1 pl-3">
          <select
            name="likert"
            @click="editing()"
            v-model="question.likert"
            @change="updateLikert(question.likert, question.docId)"
            class="block w-full h-10 py-2 pl-3 pr-10 mt-1 text-base bg-white border-gray-300 rounded-md dark:border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600"
          >
            <option></option>
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
          </select>
        </div>
        <!-- <div class="w-16 px-3 py-1 text-center">
          <input
            @click="updateRecommended()"
            class="bg-white dark:bg-gray-600 dark:border-gray-800"
            type="checkbox"
            id="checkbox"
            v-model="question.recommended"
          />
        </div> -->
        <Recommended 
          :isRecommended="question.recommended" 
          :client="client"
          :section="section"
          :docId="question.docId"
          />

        <Comment
          :id="question.docId"
          :client="client"
          :section="section"
          :rowId="question.rowId"
          :savedComments="question.comments"
          :userName="userName"
        />
      </div>
      <div
        v-if="helpSection"
        class="flex items-start w-2/3 py-3 pl-3 pr-4 text-gray-700 bg-gray-100 border-b border-gray-200 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-700"
      >
        <button @click="helpSection = !helpSection" class="mt-1 mr-12 focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 text-blue-800 dark:text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <ul>
          <li v-for="(tip, index) in question.tips" :key="index" class="mt-1 ">
            {{ tip.tip }}
          </li>
        </ul>
      
    
    </div>
        <!-- <Question
          v-if="questionRef.active"
          :question="questionRef"
          :section="section"
          :client="client"
          :rowId="questionRef.rowId"
          :docId="questionRef.docId"
          :comments="questionRef.comments"
          :questionNum="index + 1"
          :likert="questionRef.likert"
          :userName="userName"
        /> -->
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { repgen } from "@/firebase";
  import { doc, onSnapshot, query, orderBy, updateDoc, collection } from "firebase/firestore"

  // import Question from "@/components/repgen/RepgenQuestion.vue";
  import Comment from "@/components/repgen/RepgenComment.vue";
  import Recommended from "@/components/repgen/RepgenRecommended.vue";
  export default {
    props: ["section", "client", "questionNum", "userName"],
    components: {
      // Question,
      Comment,
      Recommended,
    },
    data() {
      return {
        count: 0,
        repgenSection: [],
        test: [],
        questionArray: [],
        helpSection: false,
        promptSection: false,
        owner: "",
        prompts: []
        // likert: this.question.likert,
        
      };
    },

    mounted() {
      this.getQuestions();
      this.loadSectionData();
    },
    methods: {
      countQuestions(index) {
        this.count = this.questionNum + index;
      },
      async getQuestions() {
        const q = query(collection(repgen, "clients", this.client, this.section), orderBy("order"));
        onSnapshot(q, {includeMetadataChanges: true}, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var client = doc.data();
            client.docId = doc.id;
            // console.log(client);
            this.questionArray.push(client);
          });
          this.repgenSection = this.questionArray;
          this.questionArray = [];
        });
      }, 
      async loadSectionData() {
        onSnapshot(doc(repgen, "sections", this.section), (snapshot) => {
          let data = snapshot.data()
          this.owner = data.owner;
          this.prompts = data.prompts;
        })
      },
      async updateLikert(likert, docId) {
        this.editEvent = false;
        var data = {
          likert: likert,
        };
        await updateDoc(doc(repgen, "clients", this.client, this.section, docId), data);
      },
    },
  };
</script>

<style></style>
